:root {
    --navdark: #141515;
    --bg: #181a1b;
    --primary: #cb4343;
}

html {
    background-color: var(--bg);
}


.App {
    height: 90vh;
    width: 99vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 9vh !important;
    margin: 0;
}

header {
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

h1 {
    font-family: 'Poppins', sans-serif;
    color: var(--primary);
}

button {
    cursor: pointer;
}